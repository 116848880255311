import { ReactNode } from 'react';

import { InputWrapperProps, Box, StatusMessage } from '@storyofams/react-ui';
import { pick } from '@styled-system/props';
import { Text } from '../Text';

export const InputWrapper = ({
  label,
  status,
  statusMessage,
  error,
  disabled,
  id,
  children,
  type,
  ...props
}: InputWrapperProps & {
  disabled?: boolean;
  type?: string;
  children: ReactNode;
}) => {
  return (
    <Box {...pick(props)}>
      {label && (
        <Text
          as="label"
          variant="label"
          mb={1}
          display="inline-block"
          htmlFor={id}
          position="relative"
          color={disabled && 'grey300'}
        >
          {label}
        </Text>
      )}

      {children}

      {(!!statusMessage || error) && (
        <StatusMessage
          status={error ? 'error' : status}
          mt={1.5}
          color={error || status === 'error' ? 'error600' : 'grey800'}
          fontWeight="normal"
          textAlign={type || error ? 'right' : 'left'}
        >
          {error || statusMessage}
        </StatusMessage>
      )}
    </Box>
  );
};
